<template>
  <div class="parcel-form">
    <v-form ref="form" class="parcel-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">Create Parcel</h3>
      <v-row>
        <v-col cols="12" md="4">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="formBuilding.building_id"
            :error-messages="formBuilding.$getError('building_id')"
            :loading="formBuilding.$busy"
            :disabled="formBuilding.$busy"
            @input="setFormByBuilding"
          />
        </v-col>
      </v-row>
      <div v-for="(form, index) in forms" :key="index">
        <v-row>
          <v-col class="parcel-form__form-row">
            <div class="parcel-form__form-row-wrapper">
              <span class="red--text"><strong>* </strong></span>
              <label class="text-field-label">Property</label>
              <SelectProperty
                flat
                solo
                required
                class="mt-2"
                placeholder="Select Property"
                v-model="form.property_id"
                :buildingId="formBuilding.building_id"
                :error-messages="form.$getError('property_id')"
                :error="form.$hasError('property_id')"
                :loading="formBuilding.$busy"
                :disabled="formBuilding.$busy || form.$busy"
                hide-details="auto"
              />
            </div>
            <div class="parcel-form__form-row-wrapper">
              <span class="red--text"><strong>* </strong></span>
              <label class="text-field-label">Recipient</label>
              <SelectUser
                flat
                solo
                required
                pre-select
                class="mt-2"
                placeholder="Select User"
                v-model="form.user_id"
                :propertyId="form.property_id"
                :error-messages="form.$getError('user_id')"
                :error="form.$hasError('user_id')"
                :loading="form.$busy"
                :disabled="formBuilding.$busy || form.$busy"
                hide-details="auto"
              />
            </div>
            <div class="parcel-form__form-row-wrapper">
              <span class="red--text"><strong>* </strong></span>
              <label class="text-field-label">Type</label>
              <SelectParcelType
                flat
                solo
                required
                pre-select
                class="mt-2"
                v-model="form.parcel_type_id"
                :buildingId="formBuilding.building_id"
                :error-messages="form.$getError('parcel_type_id')"
                :error="form.$hasError('parcel_type_id')"
                :loading="form.$busy"
                :disabled="formBuilding.$busy || form.$busy"
                hide-details="auto"
                text-column="type"
              />
            </div>
            <div class="parcel-form__form-row-wrapper">
              <label class="text-field-label">Parcel Reference</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                placeholder="Parcel Reference"
                v-model="form.parcel_id"
                :error-messages="form.$getError('parcel_id')"
                :loading="form.$busy"
                :disabled="form.$busy"
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="parcel-form__form-row-wrapper">
              <span class="red--text"><strong>* </strong></span>
              <label class="text-field-label">Parcel Location</label>
              <SelectParcelLocation
                flat
                solo
                required
                pre-select
                class="mt-2"
                v-model="form.parcel_location_id"
                :buildingId="formBuilding.building_id"
                :error-messages="form.$getError('parcel_location_id')"
                :error="form.$hasError('parcel_location_id')"
                :loading="form.$busy"
                :disabled="formBuilding.$busy || form.$busy"
                hide-details="auto"
              />
            </div>
            <div class="parcel-form__form-checkbox-group">
              <div class="checkbox-wrapper">
                <label class="text-field-label">SMS</label>
                <v-checkbox
                  v-model="form.send_sms"
                  hide-details="auto"
                ></v-checkbox>
              </div>

              <div class="checkbox-wrapper">
                <label class="text-field-label">Email</label>
                <v-checkbox
                  v-model="form.send_email"
                  hide-details="auto"
                ></v-checkbox>
              </div>

              <div class="checkbox-wrapper">
                <label class="text-field-label">Alert</label>
                <v-checkbox
                  v-model="form.send_push_noti"
                  hide-details="auto"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-divider class="ma-4" v-if="index < forms.length - 1"></v-divider>
        </v-row>
      </div>

      <v-row>
        <v-col cols="12" md="4">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :disabled="disableButton"
              >Create</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Parcel Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectBuilding from '@/components/fields/SelectBuilding'
import SelectProperty from '@/components/fields/SelectProperty'
import SelectUser from '@/components/fields/SelectUser'
import SelectParcelType from '@/components/fields/SelectParcelType'
import SelectParcelLocation from '@/components/fields/SelectParcelLocation'
import { FILED_REQUIRED } from '@/utils/enums/MessageAlert'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectBuilding,
    SelectProperty,
    SelectUser,
    SelectParcelType,
    SelectParcelLocation,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    perk: {
      type: Object,
      default: () => {
        return null
      },
    },
    accessBuilding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      forms: Array.from({ length: 2 }, () => this.initForm()),
      formBuilding: new Form({ building_id: '' }),
    }
  },

  computed: {
    ...mapState({
      createdPerk: (state) => state.perk.perkDetails,
      permissions: (state) => state.auth.permissions,
    }),

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.PARCEL_CREATE], this.permissions)
    },

    disableButton() {
      return !this.forms.some((form) => this.isFormModified(form))
    },
  },

  watch: {
    forms: {
      handler(newValue, oldValue) {
        this.checkAndAddForm(newValue)
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      createParcel: 'parcel/createParcel',
    }),

    initForm() {
      return new Form({
        property_id: '',
        user_id: '',
        parcel_type_id: '',
        parcel_id: '',
        parcel_location_id: '',
        send_sms: false,
        send_email: false,
        send_push_noti: true,
      })
    },

    async submit() {
      if (!this.validate()) return

      this.clearFormErrors()

      await this.createParcel(this.getFormData())
        .then(() => {
          this.showSnackbar('Parcel successfully created!')
          this.$router.push({
            name: 'parcel.dashboard',
          })
        })
        .catch((err) => {
          this.showSnackbar(this.getValidationErrors(err), false)
        })
    },

    isFormModified(formData) {
      const form = formData.$data()
      return Object.keys(form).some((key) => form[key])
    },

    clearFormErrors() {
      for (var form of this.forms) {
        form.$clearErrors()
      }
      this.formBuilding.$clearErrors()
    },

    getFormData() {
      let formSubmit = []
      for (var form of this.forms) {
        const data = form.$data()
        if (this.isFormModified(form) && this.isFormComplete(form))
          formSubmit.push({
            ...data,
            send_sms: form.send_sms ? 1 : 0,
            send_email: form.send_email ? 1 : 0,
            send_push_noti: form.send_push_noti ? 1 : 0,
          })
      }
      return { building_id: this.formBuilding.building_id, parcels: formSubmit }
    },

    isFormComplete(form) {
      return (
        !!form.property_id &&
        !!form.user_id &&
        !!form.parcel_type_id &&
        !!form.parcel_location_id
      )
    },

    checkAndAddForm(newForms) {
      let forms = newForms
      for (const [index, form] of newForms.entries()) {
        if (this.isFormComplete(form) && index + 2 > newForms.length - 1) {
          forms.push(this.initForm())
        }
      }

      this.forms = forms
    },

    setFormByBuilding() {
      const formReset = []

      for (var form of this.forms) {
        const formData = form.$data()

        formReset.push(
          new Form({
            ...formData,
            property_id: '',
            parcel_type_id: '',
            parcel_location_id: '',
            user_id: '',
          })
        )
      }

      this.forms = formReset
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.clearFormErrors()
      let errors = []

      if (!this.formBuilding.building_id) {
        this.formBuilding.$setError('building_id', FILED_REQUIRED)
        if (this.formBuilding.$hasErrors()) {
          errors.push(1)
        }
      }
      for (var [index, form] of this.forms.entries()) {
        if (
          (index > 0 && !form.property_id && !form.user_id) ||
          !this.isFormModified(form)
        ) {
          continue
        }

        if (!form.property_id) {
          form.$setError('property_id', FILED_REQUIRED)
        }

        if (!form.user_id) {
          form.$setError('user_id', FILED_REQUIRED)
        }

        if (!form.parcel_type_id) {
          form.$setError('parcel_type_id', FILED_REQUIRED)
        }

        if (!form.parcel_location_id) {
          form.$setError('parcel_location_id', FILED_REQUIRED)
        }

        if (form.$hasErrors()) {
          errors.push(1)
        }
      }

      return errors.length === 0
    },
  },
}
</script>
<style lang="scss">
.parcel-form {
  &__form {
    max-width: 100%;
  }

  &__form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  @media (max-width: 540px) {
    &__form-row-wrapper,
    &__form-checkbox-group {
      min-width: 100%;
    }
  }

  &__form-row-wrapper {
    width: 250px;
  }

  &__form-checkbox-group {
    display: flex;
    gap: 40px;
    max-height: 82px;

    .checkbox-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.v-menu__content {
  width: 250px !important;
}
</style>
